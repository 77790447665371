<template>
    <div class="hotel-partners d-none d-md-block">
        <h3 class="mb-3 grid-title">{{ $t('HotelPartners.title') }}</h3>
        <div class="hotel-partners__container container-fluid">
            <div class="row hotel-partners__wrapper">
                <div
                    v-for="([partner, sources], index) in map"
                    :key="'partner' + index"
                    class="col-4 col-md-3 col-lg-2"
                >
                    <ImageFluid
                        :src="sources.src"
                        :webp-src="sources.webpSrc"
                        class="hotel-partners__image-wrapper"
                        img-class="hotel-partners__image"
                        :alt="partner"
                        width="200"
                        height="120"
                    />
                    <!--                    <picture class="hotel-partners__image-wrapper">
                        <source
                            v-if="sources.webpSrc"
                            :srcset="sources.webpSrc"
                            type="image/webp"
                            class="hotel-partners__image"
                            loading="lazy"
                        />
                        <img
                            :src="sources.src"
                            class="hotel-partners__image"
                            width="200"
                            height="120"
                            loading="lazy"
                            :alt="partner"
                        />
                    </picture>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageFluid from '../ImageFluid';

export default {
    name: 'HotelPartners',
    components: { ImageFluid },
    data() {
        return {
            hotelImageMap: {
                //SVGs should be loaded as a modules default property https://github.com/nuxt/nuxt.js/issues/7328#issuecomment-872575133
                'A&O': {
                    src: require('@/assets/partners/A&O.jpg')
                },
                Achat: {
                    src: require('@/assets/partners/Achat.jpg')
                },
                Akzent: {
                    src: require('@/assets/partners/Akzent.png'),
                    webpSrc: require('@/assets/webp/partners/Akzent.webp')
                },
                Amedia: {
                    src: require('@/assets/partners/Amedia.png'),
                    webpSrc: require('@/assets/webp/partners/Amedia.webp')
                },
                Ameron: {
                    src: require('@/assets/partners/Ameron.jpg'),
                    webpSrc: require('@/assets/webp/partners/Ameron.webp')
                },
                BestWestern: {
                    src: require('@/assets/partners/BestWestern.png'),
                    webpSrc: require('@/assets/webp/partners/BestWestern.webp')
                },
                BrownHouse: {
                    src: require('@/assets/partners/BrownHouse.png'),
                    webpSrc: require('@/assets/webp/partners/BrownHouse.webp')
                },
                Choice: {
                    src: require('@/assets/partners/Choice.svg').default
                },
                Clarion: {
                    src: require('@/assets/partners/Clarion.svg').default
                },
                Comfort: {
                    src: require('@/assets/partners/Comfort.png'),
                    webpSrc: require('@/assets/webp/partners/Comfort.webp')
                },
                CrownePlaza: {
                    src: require('@/assets/partners/CrownePlaza.png')
                },
                Dorint: {
                    src: require('@/assets/partners/Dorint.png'),
                    webpSrc: require('@/assets/webp/partners/Dorint.webp')
                },
                Flemings: {
                    src: require('@/assets/partners/Flemings.jpg'),
                    webpSrc: require('@/assets/webp/partners/Flemings.webp')
                },
                GHotel: {
                    src: require('@/assets/partners/GHotel.svg').default
                },
                GoldenTulip: {
                    src: require('@/assets/partners/GoldenTulip.svg').default
                },
                HappyCulture: {
                    src: require('@/assets/partners/HappyCulture.svg').default
                },
                Hilton: {
                    src: require('@/assets/partners/Hilton.png'),
                    webpSrc: require('@/assets/webp/partners/Hilton.webp')
                },
                HolidayInn: {
                    src: require('@/assets/partners/HolidayInn.svg').default
                },
                Ibis: {
                    src: require('@/assets/partners/Ibis.png'),
                    webpSrc: require('@/assets/webp/partners/Ibis.webp')
                },
                IbisStyles: {
                    src: require('@/assets/partners/IbisStyles.jpg'),
                    webpSrc: require('@/assets/webp/partners/IbisStyles.webp')
                },
                InterCity: {
                    src: require('@/assets/partners/InterCity.svg').default
                },
                Louvre: {
                    src: require('@/assets/partners/Louvre.svg').default
                },
                Mercure: {
                    src: require('@/assets/partners/Mercure.png'),
                    webpSrc: require('@/assets/webp/partners/Mercure.webp')
                },
                Park: {
                    src: require('@/assets/partners/Park.svg').default
                },
                ParkInn: {
                    src: require('@/assets/partners/ParkInn.png'),
                    webpSrc: require('@/assets/webp/partners/ParkInn.webp')
                },
                ParkPlaza: {
                    src: require('@/assets/partners/Parkplaza.jpg')
                },
                QualityInn: {
                    src: require('@/assets/partners/QualityInn.jpg'),
                    webpSrc: require('@/assets/webp/partners/QualityInn.webp')
                },
                RadissonInn: {
                    src: require('@/assets/partners/Radisson.png'),
                    webpSrc: require('@/assets/webp/partners/Radisson.webp')
                },
                RingHotels: {
                    src: require('@/assets/partners/Ringhotels.svg').default
                },
                Seminaris: {
                    src: require('@/assets/partners/Seminaris.svg').default
                },
                TulipInn: {
                    src: require('@/assets/partners/TulipInn.jpg'),
                    webpSrc: require('@/assets/webp/partners/TulipInn.webp')
                },
                ViennaHouse: {
                    src: require('@/assets/partners/ViennaHouse.jpg'),
                    webpSrc: require('@/assets/webp/partners/ViennaHouse.webp')
                },
                Welcome: {
                    src: require('@/assets/partners/WelcomeHotels.png')
                },
                WyndhamGarden: {
                    src: require('@/assets/partners/WyndhamGarden.png')
                }
            }
        };
    },
    computed: {
        map() {
            return Array.from(Object.entries(this.hotelImageMap));
        },

        supportsWebP() {
            return this.$store.state.webP;
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints';
@use 'sass:math';

.hotel-partners {
    $amount-of-partners: 34;

    @mixin animation-responsive($slides-per-view) {
        $slide-duration: 5s;
        $slides: math.ceil($amount-of-partners / $slides-per-view);
        $duration: $slides * $slide-duration;

        &__wrapper {
            animation-timing-function: steps($slides, end), ease-in-out;
            animation-name: translateX, fade;
            animation-duration: $duration, $slide-duration;
        }

        @keyframes translateX {
            from {
                transform: translateX(0);
            }

            to {
                transform: translateX(-#{$slides * 100%});
            }
        }
    }

    $slides-per-view: 6; //xl devices
    @include animation-responsive($slides-per-view);

    @include breakpoints.media-breakpoint-only(md) {
        $slides-per-view: 4; //amount of partners / slides per view
        @include animation-responsive($slides-per-view);
    }

    @include breakpoints.media-breakpoint-down(sm) {
        $slides-per-view: 3;
        @include animation-responsive($slides-per-view);
    }

    background-color: var(--body-bg);

    &__container {
        position: relative;
        height: 100px;
        overflow: hidden;
    }

    &__wrapper {
        flex-wrap: nowrap;
        height: 100%;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }

    &__image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    ::v-deep &__image {
        max-height: 100%;
        width: auto;
        max-width: 100%;
        height: auto;
    }

    @keyframes fade {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 1;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}
</style>
